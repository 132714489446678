require("./theme-chooser.less");
const chooserTemplate = require("./theme-chooser.hbs");

document.addEventListener("DOMContentLoaded", () => {
  createThemeChooser();
});

const themes = [
  { value: "default", name: "Standard" },
  { value: "bayern", name: "Bayern" },
  { value: "berlin", name: "Berlin" },
  { value: "dtv", name: "DTV" },
  { value: "htv", name: "HTV" },
  { value: "tnw", name: "TNW" },
  { value: "trp", name: "TRP" },
  { value: "tsh-hatv", name: "TSH/HATV" },
];

function parseCookies(value: string): Record<string, string> {
  if (value) {
    return value.split(";").reduce((prev, cur) => {
      const [name, value] = cur.split("=");
      prev[name.trim()] = value.trim();
      return prev;
    }, {} as Record<string, string>);
  } else {
    return {};
  }
}

function getCurrentSelection(
  cookies: Record<string, string>,
  cookieName: string
): string | undefined {
  if (cookies[cookieName]) {
    return cookies[cookieName];
  }
}

function createThemeChooser(cookieName = "zwe-theme") {
  let chooser = document.querySelector(".theme-chooser");
  if (!chooser) {
    chooser = document.createElement("div");
    chooser.classList.add("theme-chooser");

    const current = getCurrentSelection(parseCookies(document.cookie), cookieName);
    const items = [...themes].map((item) => {
      if (current === item.value) {
        return { ...item, selected: true };
      } else {
        return item;
      }
    });

    chooser.innerHTML = chooserTemplate({
      items,
    });

    chooser.addEventListener("zwe-theme:changed", (e: CustomEvent) => {
      document.cookie = `${cookieName}=${e.detail}; path=/`;
      window.location.reload();
    });

    document.body.append(chooser);
  }
}
